import { User, Role, SuperUserRole, Company } from "prisma/cm/client";

export function isCompanyAdmin<T extends { role: Role }>(user: T | null): boolean {
  if (!user) return false;
  return user.role === Role.COMPANY_ADMIN;
}
/*
 * Indicates internal Comprehensive admins who have access
 * to additional internal tooling. We distinguish between these
 * users and "Company Admins" who have access to administer their
 * own company.
 */
export function isSuperUser<T extends Pick<User, "superUserRole">>(
  user: T | null | undefined,
): boolean {
  if (!user) return false;
  return user.superUserRole === SuperUserRole.FULL_ACCESS;
}

export const isComprehensiveInternalUser = (user: User | null): boolean => {
  return Boolean(user?.worksForComprehensive);
};

export function hasGovernorAccessOverCompany<
  T1 extends Pick<User, "governedPartnerId" | "superUserRole">,
  T2 extends Pick<Company, "partnerId">,
>(user: T1 | null | undefined, company: T2 | null | undefined): boolean {
  if (!user) return false;
  if (isSuperUser(user)) return true;
  if (isManagedPartnerAdmin(user)) {
    return user.governedPartnerId === company?.partnerId;
  }

  return false;
}

export function isManagedPartnerAdmin<T extends Pick<User, "governedPartnerId" | "superUserRole">>(
  user: T | null | undefined,
): boolean {
  if (!user) return false;
  return user.superUserRole === SuperUserRole.PARTNER_ADMIN;
}
